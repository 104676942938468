import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import data from "../data/data.js";
import { Button } from "flowbite-react";
import { Carousel } from "flowbite-react";
import PdpAccordion from "./PdpAccordion.js";
import "./Pdp.css";
import FormModal from "./FormModal";

const ProductDetail = () => {

  const { productName, subcategory } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [indicator, Setindicator] = useState(true)

  function onCloseModal() {
    setOpenModal(false);
    setEmail("");
  }

  useEffect(() => {
    const findProduct = getProductByName(productName, subcategory)
    if (!findProduct) {
      setProduct(null);
    } else {
      setProduct(findProduct);
      if( findProduct.name === "Transformador" || findProduct.name ==="Control de luz"){
        Setindicator(false)
      }
      setLoading(false);
    }
  }, [productName]);

  if (loading) {
    return <p></p>;
  }
  if (!product) {
    return <NotFound />;
  }

  return (
    <section className="pdp-content">
      <div className="pdp-container">
        <section className="carousel-container">
          <div className="carousel-container-container">
          {!product.uniqueimage ?
          <Carousel indicators={indicator}> 
              <div className="image-container" id={product.name + product.subcategory}>
                <img 
                  src={require("../assets/" + product.image + ".webp")}
                  alt={product.name}
                />
              </div>
              {product.images? product.images.map((item, key) => (
                  <div className="image-container" id={product.name + product.subcategory + key} key={item}>
                    <img 
                      src={require("../assets/product/" + item + ".webp")}
                      alt={product.name} loading="lazy"
                    />
                  </div>
                ))
                :
                <div className="image-container" id={product.name + product.subcategory}>
                <img
                src={require("../assets/product/" + product.subcategory.toLowerCase()+ " "+
                  product.name.toLowerCase() +
                  ".webp") } loading="lazy"
                alt={product.name}
              />
              </div> 
              // :
            //   <div>
            //   <img
            //     src={require("../assets/" + product.image + ".webp")}
            //     alt={product.name} loading="lazy"
            //   />
            // </div>
             }
              
            </Carousel>
                  
                :
                <div id={product.name + product.subcategory} className="image-container">
                
                <img
                  src={require("../assets/" + product.image + ".webp")}
                  alt={product.name}
                />
              </div>
                }
            
          </div>
        </section>
        <section className="pdp-details-container">
          <h1 className="pdp-title">
            {productName}
          </h1>
          <span>
            {`${product.subcategory.charAt(0).toUpperCase()+
            product.subcategory.slice(1)} `}
          </span>
          {product.size && <span className="pdp-product-size">{product.size + " "}</span>}
          <article className="pdp-details">
            {product.subcategory!=="Iluminación" && product.subcategory!=="Vigas y Esquineros" &&  <span>
              "NOTA: Te recomendamos agregar un 10% extra al total de tu pedido,
              esto por temas de corte y pedacería que puedan surgir en la
              instalación."
            </span>}
          
            <PdpAccordion product={product}></PdpAccordion>
            <article className="pdp-buttons">
              {product.datasheet && 
                <a className="product-button" href={require(`../assets/datasheet/${product.category} ${product.subcategory.toLowerCase()} ${productName.toLowerCase()}.pdf`)} target="_blank" rel="noopener noreferrer">
                  Ficha Técnica
                </a>
               }
              <Button
                className="product-button"
                onClick={() => setOpenModal(true)}
              >
                Realizar Pedido
              </Button>
              <FormModal
                openModal={openModal}
                onCloseModal={onCloseModal}
                email={email}
                setEmail={setEmail}
              ></FormModal>
            </article>
          </article>
        </section>
      </div>
    </section>
  );
};

const getProductByName = (productName, subcategory) => {
  let result = data.products.filter((items) => items.subcategory === subcategory);
  result = result.find((product) => product.name === productName);
  return result;
};


export default ProductDetail;
