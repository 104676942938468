export default {
    "categories": [
        {
            id: 1,
            name: "Paneles",
            category: "wpc",
            image: "WoodArt Walnut-1"
        },
        {
            id: 2,
            name: "Piso Vinílico",
            category: "lvt",
            image: "CONCRETE OXFORD"
        },
        {
            id: 3,
            name: "Duela Vinílica",
            category: "spc",
            image: "DOUGLAS NATURAL"
        },
        {
            id: 4,
            name: "Muros",
            category: "muros",
            image: "DOUGLAS NATURAL"
        },
    ],
     "products": [
        {
            id: 1,
            name: "Cherry",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Cherry"],
            piece: "10",
            image: "Woodart Cherry",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 2,
            name: "Nero",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color:["Nero"],
            piece: "10",
            image: "WoodArt Nero-1",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 3,
            name: "Roble",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Roble"],
            piece: "10",
            image: "WoodArt Roble",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 4,
            name: "Nogal",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Nogal"],
            piece: "10",
            image: "WoodArt Nogal",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 5,
            name: "Walnut",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Walnut"],
            piece: "10",
            image: "WoodArt Walnut",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 6,
            name: "Cinnamon",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Cinnamon"],
            image: "WoodArt Cinnamon",
            piece: "10",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 7,
            name: "Maple",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Maple"],
            image: "Woodart Maple",
            piece: "10",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true
        },
        {
            id: 8,
            name: "Teka",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Teka"],
            image: "WoodArt Teka",
            piece: "10",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true
        },
        {
            id: 10,
            name: "Bianco",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Bianco"],
            image: "SETTE BIANCO",
            piece: "10",
            description: "Panel decorativo de siete bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        //VIEJO
        /*
        {
            id: 11,
            name: "Capuchino",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Capuchino"],
            image: "SETTE CAPPUCCINO",
            piece: "10",
            description: "Panel decorativo de siete bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        */
        {
            id: 12,
            name: "Walnut",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Walnut"],
            image: "SETTE WALNUT",
            piece: "10",
            description: "Panel decorativo de siete bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet:true,
        },
        {
            id: 13,
            name: "Star",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Star"],
            image: "SETTE STAR",
            piece: "10",
            description: "Panel decorativo de siete bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        {
            id: 14,
            name: "Maple",
            category: "wpc",
            subcategory: "panel",
            size: ["290 x 60 x 0.8 cm"],
            color: ["Maple"],
            // quantity: "4.35m²",
            image: "PANEL MAPLE",
            // piece: "10",
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        {
            id: 15,
            name: "Walnut",
            category: "wpc",
            subcategory: "panel",
            size: ["290 x 60 x 0.8 cm"],
            color: ["Walnut"],
            image: "PANEL WALNUT",
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        // {
        //     id: 16,
        //     name: "Uv Marble",
        //     category: "wpc",
        //     subcategory: "Uv Marble",
        //     size: ["244 x 122 x 0.3 cm"],
        //     color: ["Onix Shine", "Calacatta Shine", "Statuario Shine", "Markina Nero Shine"],
        //     image: "UV MARBLE UV MARBLE",
        //     quantity: "2.97m²",
        //     images: ["uv marble pieza 1", "uv marble pieza 2", "uv marble pieza 3", "uv marble pieza 4"],
        //     description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
        // },
        // {
        //     id: 17,
        //     name: "Barra Maple",
        //     category: "wpc",
        //     subcategory: "Barras y Esquineros",
        //     size: ["290 x 10 x 5 cm"],
        //     color: ["Maple"],
        //     image: "BARRAS Y ESQUINEROS MAPLE",
        //     description: "Barra decorativa, que permite crear y armonizar tus espacios de una manera auténtica.",
        // },
        // {
        //     id: 18,
        //     name: "Barra Walnut",
        //     category: "wpc",
        //     subcategory: "Barras y Esquineros",
        //     size: ["290 x 10 x 5 cm"],
        //     color: ["Walnut"],
        //     image: "BARRAS Y ESQUINEROS WALNUT",
        //     description: "Barra decorativa, que permite crear y armonizar tus espacios de una manera auténtica.",
        // },
        {
            id: 22,
            name: "Luz Led",
            category: "wpc",
            size: ["60 cm", "100 cm", "150 cm" ],
            subcategory: "Iluminación",
            piece: "1",
            other: "100 cm controlador",
            image: "ILUMINACION LUZ LED QUATTRO & SETTE",
            images: ["iluminación luz led", "iluminación luz led 2"],
            description: "Luz Led Quattro & Sette",
        },
        {
            id: 20,
            name: "Transformador",
            category: "wpc",
            subcategory: "Iluminación",
            piece: "1",
            other: "150w | Entrada 110v | Salida 24v",
            image: "ILUMINACION TRANSFORMADOR",
            description: "Refacción de transformador.",
            uniqueimage:true,
        },
        //VIEJO
        /*
        {
            id: 21,
            name: "Control de luz",
            category: "wpc",
            subcategory: "Iluminación",
            piece: "1",
            other: "Requiere pila CR2032",
            image: "ILUMINACION CONTROL DE LUZ",
            description: "Control de luz",
            uniqueimage:true,
        },
        */
        {
            id: 23,
            name: "Brown",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Douglas",
            color: ["Brown"],
            image: "DOUGLAS NATURAL",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 24,
            name: "Natural",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Lawson",
            color: ["Natural"],
            image: "LAWSON NATURAL",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        //viejo
        /*
        {
            id: 25,
            name: "Nogal",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Lawson",
            color: ["Nogal"],
            image: "SPC LAWSON NOGAL",
            images: ["lawson nogal"],
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },*/
        //Viejo
        /*
        {
            id: 26,
            name: "Ash",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Lawson",
            color: ["Ash"],
            image: "LAWSON ASH",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },*/
        {
            id: 27,
            name: "Natural",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Palm",
            color: ["Natural"],
            image: "PALM NATURAL",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 28,
            name: "Haya",
            category: "lvt",
            size: ["17.78 x 121.92 cm" ],
            subcategory: "Forest",
            color: ["Haya"],
            image: "FOREST HAYA",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 29,
            name: "Roble",
            category: "lvt",
            size: ["17.78 x 121.92 cm" ],
            subcategory: "Forest",
            color: ["Roble"],
            image: "FOREST ROBLE",  
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 30,
            name: "Grey",
            category: "lvt",
            size: ["17.78 x 121.92 cm" ],
            subcategory: "Forest",
            color: ["Grey"],
            image: "FOREST GREY",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        /*
        {
            id: 31,
            name: "Nuez",
            category: "lvt",
            size: ["17.78 x 121.92 cm" ],
            subcategory: "Forest",
            color: ["Nuez"],
            image: "FOREST NUEZ",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 32,
            name: "Nogal",
            category: "lvt",
            size: ["17.78 x 121.92 cm" ],
            subcategory: "Forest",
            color: ["Nogal"],
            image: "FOREST NOGAL",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },*/
        {
            id: 33,
            name: "Grey",
            category: "lvt",
            size: ["91.44 x 45 cm" ],
            subcategory: "Concrete",
            color: ["Grey"],
            image: "CONCRETE GREY",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 34,
            name: "Oxford",
            category: "lvt",
            size: ["91.44 x 45 cm" ],
            subcategory: "Concrete",
            color: ["Oxford"],
            image: "CONCRETE OXFORD",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 35,
            name: "Black",
            category: "lvt",
            size: ["91.44 x 45 cm"],
            subcategory: "Ozz",
            color: ["Black"],
            image: "OZZ BLACK",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 36,
            name: "Neve",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Neve"],
            piece: "10",
            image: "QUATTRO NEVE",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 37,
            name: "Azur",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Azur"],
            piece: "10",
            image: "QUATTRO AZUR",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 38,
            name: "Oxford",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 16 x 2.4 cm"],
            color: ["Oxford"],
            piece: "10",
            image: "QUATTRO OXFORD",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 39,
            name: "Pianni Ebano Mix",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 15.2 x 1.2 cm"],
            color: ["Ebano Mix"],
            piece: "10",
            image: "QUATTRO PIANNI",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 40,
            name: "Pianni Silk Pearl",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 15.2 x 1.2 cm"],
            color: ["Silk Pearl"],
            piece: "10",
            image: "QUATTRO PIANNI SILK PEARL",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 41,
            name: "Pianni Silk Vanilla",
            category: "wpc",
            subcategory: "quattro",
            size: ["290 x 15.2 x 1.2 cm"],
            color: ["Silk vanilla"],
            piece: "10",
            image: "QUATTRO PIANNI SILK VANILLA",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 42,
            name: "Azur",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Azur"],
            piece: "10",
            image: "SETTE AZUR",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 43,
            name: "Tre Pianni X",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Pianni"],
            piece: "10",
            image: "SETTE PIANNI X",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 44,
            name: "Cannoli",
            category: "wpc",
            subcategory: "sette",
            size: ["290 x 15 x 1.5 cm"],
            color: ["Cannoli"],
            piece: "10",
            image: "SETTE CANNOLI",
            description: "Panel decorativo de cuatro bloques, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 45,
            name: "Cinnamon",
            category: "wpc",
            subcategory: "panel",
            size: ["290 x 60 x 0.8 cm"],
            color: ["Cinnamon"],
            image: "PANEL CINNAMON",
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 46,
            name: "Nogal",
            category: "wpc",
            subcategory: "panel",
            size: ["290 x 60 x 0.8 cm"],
            color: ["Nogal"],
            image: "PANEL NOGAL",
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 47,
            name: "Viga Nogal",
            category: "wpc",
            subcategory: "Vigas y Esquineros",
            size: ["290 x 10 x 5 cm", "290 x 15 x 5 cm"],
            color: ["Nogal"],
            image: "VIGA NOGAL",
            description: "Viga decorativa que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 48,
            name: "Viga Cinnamon",
            category: "wpc",
            subcategory: "Vigas y Esquineros",
            size: ["290 x 10 x 5 cm", "290 x 15 x 5 cm"],
            color: ["Cinnamon"],
            image: "VIGA CINNAMON",
            description: "Viga decorativa que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 19,
            name: "Esquinero",
            category: "wpc",
            subcategory: "Vigas y Esquineros",
            size: ["290 x 2.5 x 2.5 cm"], //verificAR INFORMACI
            // color: ["Walnut"],
            other: "Disponible en todos los colores de la colección WPC",
            image: "BARRAS Y ESQUINEROS ESQUINERO",
            description: "Esquinero decorativa, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
        {
            id: 49,
            name: "Lawson",
            category: "spc",
            subcategory: "Zoclo SPC",
            size: ["220 x 5 cm"],
            color: ["Natural", "Silver"],
            image: "ZOCLO LAWSON NATURAL",
            images: ["zoclo lawson silver"],
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        {
            id: 50,
            name: "Douglas",
            category: "spc",
            subcategory: "Zoclo SPC",
            size: ["220 x 5 cm"],
            color: ["Grey", "Brown"],
            image: "ZOCLO DOUGLAS GREY",
            images: ["zoclo douglas brown"],
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
        },
        {
            id: 51,
            name: "Palm",
            category: "spc",
            subcategory: "Zoclo SPC",
            size: ["220 x 5 cm"],
            color: ["Natural"],
            image: "ZOCLO PALM",
            description: "Panel decorativo que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },
      
        {
            id: 52,
            name: "Gray",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Douglas",
            color: ["Gray"],
            images: ["spc douglas gray"],
            image: "SPC DOUGLAS GRAY",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        {
            id: 53,
            name: "Silver",
            category: "spc",
            size: ["18.2 x 122 cm" ],
            subcategory: "Lawson",
            color: ["Silver"],
            image: "SPC LAWSON SILVER",
            images: ["lawson silver"],
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            datasheet: true,
        },
        //VIEJO//
        /*{
            id: 54,
            name: "Carrara",
            category: "lvt",
            size: ["91.44 x 45 cm"],
            subcategory: "Ozz",
            color: ["Carrara"],
            image: "OZZ CARRARA",
            description: "Panel decorativo, que permite crear y armonizar tus espacios de una manera auténtica.",
            uniqueimage:true,
        },*/
        {
            id: 55,
            name: "Clips",
            category: "wpc",
            subcategory: "Vigas y Esquineros",
            image: "CLIPS",
            description: "Clips de colocación",
            uniqueimage:true,
        },
        //WPC EXTERIOR EN CATALOGO
          {
            id: 56,
            name: "Plafón",
            category: "wpc",
            size: ["290 X 17.4 X 2.1 cm" ],
            subcategory: "Exterior",
            image: "plafon",
            description: "Exteriores WPC Muros",
            color: ["Chocolate", "Caramel"],
            images: ["plafon caramel", "plafon chocolate"],
        },
        /*
        FALTAN IMAGENES
        {
            id: 57,
            name: "Quattro Exterior",
            category: "wpc",
            subcategory: "Exterior",
            size: ["290 X 17.4 X 2.1 cm" ],
            image: "plafon",
            description: "Exteriores WPC Muros",
            color: ["Chocolate", "Caramel"],
            images: ["plafon caramel", "plafon chocolate"],
        },
        */
        {
            id: 58,
            name: "Vigas",
            category: "wpc",
            subcategory: "Exterior",
            size: ["290 X 10 X 5 cm" ],
            image: "vigas exterior",
            description: "Exteriores WPC Muros",
            color: ["Chocolate", "Caramel"],
            images: ["viga exterior caramel", "viga exterior chocolate"],
        },
        {
            id: 59,
            name: "Sans Golden",
            category: "muros",
            subcategory: "Burano",
            size: ["42.5 X 60 X 2.8 cm" ],
            image: "sans golden",
            description: "Muros Serie Burano",
            color: ["Golden"],
            images: ["sans golden"],
        },
        {
            id: 60,
            name: "Sans Black",
            category: "muros",
            subcategory: "Burano",
            size: ["42.5 X 60 X 2.8 cm" ],
            image: "sans black",
            description: "Muros Serie Burano",
            color: ["Golden"],
            images: ["sans black 1", "sans black 2"],
        },

        {
            id: 61,
            name: "UV Marble",
            category: "muros",
            subcategory: "Decorative Panel",
            size: ["280 X 122 X .3 cm" ],
            image: "uv marble",
            description: "Paneles Decorativos",
            color: ["ST Bars", "Domek", "Salon Black"],
            images: ["uv marble domek", "uv marble salon black", "uv marble st barts"],
        },
        {
            id: 62,
            name: "Electro",
            category: "muros",
            subcategory: "Decorative Panel",
            size: ["280 X 122 X .3 cm" ],
            image: "electro",
            description: "Paneles Decorativos",
            color: ["Dinamic Black", "Grey", "Titanium", "Bronze"],
            images: ["electro dinamic black", "electro grey", "electro titanium", "electro bronze"],
        }
/*
        {
            id: 62,
            name: "Deck",
            category: "piso exterior",
            subcategory: "Deck",
            size: ["290 X 13.8 X 2.3 cm" ],
            image: "electro",
            description: "Piso Decorativo Deck",
            color: ["Dinamic Black", "Grey", "Titanium", "Bronze"],
            images: ["electro dinamic black", "electro grey", "electro titanium", "electro bronze"],
        },*/
      
     ]
   
}