import "./Card.css"
import { Link } from 'react-router-dom';
function Card({ product }) {
  const { name, category, image, subcategory } = product;
  const pathname = window.location.pathname


  return (
    <>
    {pathname !=="/"?
    <Link to={`/productos/${product.subcategory}/${product.name}`} className="card-container">
    {/* <article > */}
      <img id={"image-"+product.id} src={require('../assets/' + image + '.webp')} className= "product-image" alt={name} loading="lazy" />
      <div className="card-text-button">
        <div className="card-text">
        <p className="product-category">{name}</p>
        <p className="product-name">{!subcategory ? category.toUpperCase():subcategory.toUpperCase() }</p>
       
        </div>
        {/* {pathname !=="/"?
         <Link to={`/productos/${product.subcategory}/${product.name}`}><p className="product-button">Descubre más</p></Link> :
         <Link to={`/categoria/${product.category}`}><p className="product-button">Descubre más</p></Link> 
      } */}
      </div>
    {/* </article> */}
    </Link> 
  :

<Link to={`/categoria/${product.category}`} className="card-container">
{/* <article > */}
  <img id={"image-"+product.id} src={require('../assets/' + image + '.webp')} className= "product-image" alt={name} loading="lazy" />
  <div className="card-text-button">
    <div className="card-text">
    <p className="product-category">{!subcategory ? category.toUpperCase():subcategory.toUpperCase() }</p>
    <p className="product-name">{name}</p>
    </div>
    {/* {pathname !=="/"?
     <Link to={`/productos/${product.subcategory}/${product.name}`}><p className="product-button">Descubre más</p></Link> :
     <Link to={`/categoria/${product.category}`}><p className="product-button">Descubre más</p></Link> 
  } */}
  </div>
{/* </article> */}
</Link> 
}
</>
)};

export default Card;
